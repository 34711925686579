import {
  Center,
  Container,
  createStyles,
  Title,
  Text,
  Input,
  Button,
  Alert,
  TextInput,
  Stack,
  Textarea, Box,
} from "@mantine/core"
import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react"
import { IMaskInput } from "react-imask"
import { useForm, yupResolver } from "@mantine/form"
import * as Yup from "yup"
import { type  AxiosError } from "axios"
import { sendParticipationRequest } from "../../shared/api/mail"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",

    backgroundImage: "url(assets/about-bg.jpg)",
    backgroundAttachment: "fixed",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,

    "&::before": {
      content: "''",
      backgroundColor: "rgb(255,255,255, 0.9)",
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 48,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors["dark-green"][8],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },

  box: {
    zIndex: 2,
  },
}))

const schema = Yup.object().shape({
  name: Yup.string().required("Имя обязательно"),
  phone: Yup.string().required("Телефон обязателен").length(18, "Пожалуйста, укажите телефон полностью"),
})

export const Form = observer(function Form() {
    const { classes } = useStyles()
    const matched = useMediaQuery(`(max-width: 768px)`)
    const is425 = useMediaQuery(`(max-width: 425px)`)

    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ isSuccess, setIsSuccess ] = useState<boolean>(false)
    const [ error, setError ] = useState<string | null>(null)

    const form = useForm({
      validateInputOnChange: true,
      validate: yupResolver(schema),
      initialValues: {
        name: "",
        phone: "",
        message: "",
      },
    })

    const onSubmit = (values: typeof form.values): void => {
      setIsLoading(true)
      setIsSuccess(false)
      setError(null)

      const body = {
        ...values,
        target: "Конференция по нутрициологии. Форма внизу у футера",
      }

      void sendParticipationRequest(body)
        .then(() => {
          setIsSuccess(true)
        })
        .catch((err: AxiosError<{ message: string }>) => {
          if (err.response) {
            setError(err.response.data.message)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    useEffect(() => {
      return () => {
        form.reset()
        setIsLoading(false)
        setIsSuccess(false)
        setError(null)
      }
    }, [])

    return (
      <section id="askQuestion" className={classes.wrapper}>
        <Container size={1320}>
          <Center className={classes.title}>
            <Title color="#122c0f" order={2} align="center" size={32}>Форма обратной связи</Title>
            <Text align="center" mt="sm" color="#122c0f">Работаем без перерывов и выходных</Text>
          </Center>

          <Box className={classes.box}>
            {isSuccess ? (
                <Container size={560}>
                  <Alert
                    styles={{ title: { fontSize: 18 }, message: { fontSize: 16 } }}
                    icon={<IconCircleCheck/>}
                    title="Заявка успешно отправлена!"
                    color="green"
                    radius="md"
                  >
                    В ближайшее время с Вами свяжется наш сотрудник и ответит на все Вами вопросы.
                  </Alert>
                </Container>
              )
              : (
                <Container size={560}>
                  <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack spacing={is425 ? "md" : "xl"}>
                      <TextInput
                        disabled={isLoading}
                        placeholder="Имя"
                        radius="md"
                        size={matched ? "md" : "lg"}
                        {...form.getInputProps("name")}
                      />
                      <Input.Wrapper id="participant-form-phone" error={form.errors["phone"]} size="md">
                        <Input
                          disabled={isLoading}
                          component={IMaskInput}
                          mask="+7 (000) 000-00-00"
                          id="participant-form-phone"
                          placeholder="Телефон"
                          radius="md"
                          size={matched ? "md" : "lg"}
                          {...form.getInputProps("phone")}
                        />
                      </Input.Wrapper>
                      <Textarea
                        autosize
                        minRows={3}
                        maxRows={15}
                        disabled={isLoading}
                        {...form.getInputProps("message")}
                        placeholder="Ваше сообщение..."
                        radius="md"
                        size={matched ? "md" : "lg"}
                      />
                      <Button
                        loading={isLoading}
                        type="submit"
                        fullWidth
                        variant="gradient"
                        gradient={{ from: "dark-green.9", to: "green.8" }}
                        radius="md"
                        size={matched ? "md" : "lg"}
                      >
                        Отправить
                      </Button>

                      {error && (
                        <Alert
                          mt="xl"
                          icon={<IconAlertCircle/>}
                          title="Ошибка отправки данных"
                          color="red"
                          radius="md"
                        >
                          {error}
                        </Alert>
                      )}

                    </Stack>
                  </form>
                </Container>
              )}
          </Box>
        </Container>
      </section>
    )
  },
)
