export const menuItems = [
  { href: "main", label: "Главная" },
  { href: "schedule", label: "Расписание" },
  { href: "speakers", label: "Спикеры" },
  { href: "askQuestion", label: "Задать вопрос" },
]

export const schedule = [
  {
    time: "11:00 - 12:00",
    speakerName: "Тамаз Гаглошвили",
    description: "Терапевт, кардиолог, геронтолог, организатор здравоохранения",
    value: "gagloshvili",
    title: "Диета или статины, с чего начать?",
    small: "assets/speakers/gagloshvili/gagloshvili-small.jpg",
    cover: "assets/speakers/gagloshvili/gagloshvili.jpg",
    texts: [
      "Автор книги \"Слушай сердце. Кардиолог о мифах про самые распространенные заболевания\"",
      "Автор блога - https://www.instagram.com/blog_kardiologa",
    ],
  },
  {
    time: "11:00 - 12:00",
    speakerName: "Евгения Меглинская",
    description: "Руководитель Школы Консультантов по психологической коррекции пищевого поведения",
    value: "meglinskaya",
    title: "Интуитивное или осознанное питание?",
    small: "assets/speakers/meglinskaya/meglinskaya-small.jpg",
    cover: "assets/speakers/meglinskaya/meglinskaya.jpg",
    texts: [
      "Магистр Манчестерского Университета (University of Manchester), Великобритания",
      "Автор книг \"Стоп срывам и перееданиям\" и \"Здоровый похудизм\"",
      "Приглашенный эксперт для телеканалов \"Мама-ТВ\", \"Домашний\", \"Здоровое ТВ\"",
    ],
  },
  {
    time: "12:00 - 13:00",
    speakerName: "Денис Лебедев",
    description: "Врач-эндокринолог, специалист по клиническим исследованиям",
    value: "lebedev",
    title: "Гормоны и масса тела",
    small: "assets/speakers/lebedev/lebedev-small.jpg",
    cover: "assets/speakers/lebedev/lebedev.jpg",
    texts: [
      "Выпускник медицинского факультета СПБГУ, ординатуры и аспирантуры по эндокринологии Национального исследовательского центра им. В.А.Алмазова",
      "Автор более 10 полнотекстовых статей, в том числе, в рейтинговых зарубежных журналах",
    ],
  },
  {
    time: "12:00 - 13:00",
    speakerName: "Анастасия Левитская",
    description: "Врач-гастроэнтеролог",
    value: "levitskaya",
    title: "В погоне за желчью",
    small: "assets/speakers/levitskaya/levitskaya-small.jpg",
    cover: "assets/speakers/levitskaya/levitskaya.jpg",
    texts: [
      "Московский областной научно-исследовательский клинический институт им. М. Ф. Владимирского (ГБУЗ МО МОНИКИ им. М. Ф. Владимирского)",
      "Аспирантура по специальности Гастроэнтерология",
      "Участие (в том числе выступления) в профессиональных медицинских конференциях, написание статей, публикации ECCO, EUG и др.",
      "Работа с ординаторами и врачами первичного звена на кафедре гастроэнтерологии ФУВ МОНИКИ",
      "Работа в качестве эксперта курса DOCMASCHOOL \"Гастроэнтерология, которая работает\", \"Терапия\"",
    ],
  },
  {
    time: "13:00 - 14:00",
    speakerName: "Джесси Хмеляр",
    description: "Наставник врачей в соц. сетях, врач-гастроэнтеролог",
    title: "Личный бред мракобеса: Как стать узнаваемым нутрициологом с научным подходом?",
    value: "khmelyar",
    small: "assets/speakers/khmelyar/khmelyar-small.jpg",
    cover: "assets/speakers/khmelyar/khmelyar.jpg",
    texts: [
      "Спикер курсов DocMaSchool",
      "Сертификат Good clinical practice (GCP)",
      "2016 - \"Лечебное дело\", Балтийский федеральный университет имени Иммануила Канта",
      "2017 - Интернатура по специальности \"Терапия\", Балтийский федеральный университет имени Иммануила Канта",
      "2018 - Профессиональная переподготовка \"Общая врачебная практика (семейная медицина)\", Северо-Западный государственный медицинский университет им. И. И. Мечникова",
      "2018 - Профессиональная переподготовка \"Гастроэнтерология\", Северо-Западный государственный медицинский университет им. И. И. Мечникова",
    ],
  },
  {
    time: "13:00 - 14:00",
    speakerName: "Анна Коновалова",
    description: "Врач-эндокринолог, диетолог",
    value: "konovalova",
    title: "Можно ли всех похудеть?",
    small: "assets/speakers/konovalova/konovalova-small.jpg",
    cover: "assets/speakers/konovalova/konovalova.jpg",
    texts: [
      "Врач-эндокринолог, диетолог",
      "Член Российской ассоциации эндокринологов",
      "Член Национальной ассоциации клинического питания",
      "Член Российского союза нутрициологов, диетологов и специалистов пищевой индустрии",
      "Член Ассоциации гинекологов-эндокринологов России",
      "Член Российской ассоциации по остеопорозу",
    ],
  },
  {
    time: "14:00 - 15:00",
    speakerName: "Жанна Валова",
    description: "Педиатр, консультант по ГВ, эксперт в теме питания детей и коррекции пищевых отношений",
    value: "valova",
    title: "Расстройства питания у детей",
    small: "assets/speakers/valova/valova-small.jpg",
    cover: "assets/speakers/valova/valova.jpg",
    texts: [
      "Автор обучающего марафона \"Мы в одной ложке\" для родителей",
      "Эксперт в теме питания детей и коррекции пищевых отношений",
    ],
  },
  {
    time: "14:00 - 15:00",
    speakerName: "Татьяна Денисова",
    description: "Врач-эндокринолог, диетолог, клинический психолог",
    value: "denisova",
    title: "Переедания - это всегда РПП?",
    small: "assets/speakers/denisova/denisova-small.jpg",
    cover: "assets/speakers/denisova/denisova.png",
    texts: [
      "Окончила Первый Московский государственный Медицинский университет им. И. М. Сеченова  по специальности лечебное дело",
      "Проходила обучение в клинической ординатуре по специальности Эндокринология в Эндокринологическом Научном центре",
      "Профессиональная переподготовка по диетологии (РУДН) и клинической психологии( Институт Организационной психологии)",
      "Специализация на диетологическом консультировании пациентов с метаболическими нарушениями, избыточной массой тела, ожирением,  РПП, девушек/женщин с ФГА на фоне спорта, диет и стресса",
      "Спикер образовательной онлайн-платформы Meducation. Обучающие лекции,  вебинары для врачей, пациентов,  посвящённые эндокринным нарушениям, а также питанию в норме и  при различных патологиях",
      "Стаж работы более 13 лет",
    ],
  },
  {
    time: "15:00 - 16:00",
    speakerName: "Анна Берекетова",
    description: "Нутрициолог",
    value: "bereketova",
    title: "А нужен ли нутрициолог?",
    small: "assets/speakers/bereketova/bereketova-small.jpg",
    cover: "assets/speakers/bereketova/bereketova.jpg",
    texts: [
      "Специалист по питанию и пищевому поведению",
      "Преподаватель и методист учебного центра Body Coach",
      "Ассистент врача-диетолога Александра Бурлакова",
    ],
  },
  {
    time: "15:00 - 16:00",
    speakerName: "Валерия Федоровских",
    description: "Врач-педиатр",
    value: "fedorovskih",
    title: "Борьба за всасывание микроэлементов",
    small: "assets/speakers/fedorovskih/fedorovskih-small.jpg",
    cover: "assets/speakers/fedorovskih/fedorovskih.jpg",
    texts: [
      "Закончила педиатрический факультет ТюмГМУ с отличием",
      "Закончила ординатуру по специальности \"Педиатрия\"",
      "Преподаватель биологической химии студентам медицинских ВУЗов",
    ],
  },
  {
    time: "16:00 - 17:00",
    speakerName: "Александр Бурлаков",
    description: "Врач-диетолог доказательной медицины",
    value: "burlakov",
    title: "Фрукты и овощи уже не те",
    small: "assets/speakers/burlakov/burlakov-small.jpg",
    cover: "assets/speakers/burlakov/burlakov.jpg",
    texts: [
      "Врач-диетолог доказательной медицины. Тренер - преподаватель. Научный журналист",
      "Специалист по диетологическому сопровождению спортсменов UFC, ACA, RCC",
      "Автор книги: \"В гармонии с едой\"",
    ],
  },
  {
    time: "16:00 - 17:00",
    speakerName: "Алексей Варфоломеев",
    description: "Специалист по модификации образа жизни",
    value: "varfolomeev",
    title: "Расстрельный список рекомендаций по питанию",
    small: "assets/speakers/varfolomeev/varfolomeev-small.jpg",
    cover: "assets/speakers/varfolomeev/varfolomeev.jpg",
    texts: [
      "Специалист по модификации образа жизни",
      "Нутрициолог",
      "Судья международного класса по вейкборду IWWF/CWWC",
    ],
  },
]
