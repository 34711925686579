import {
  About,
  BeInterested,
  Features,
  Footer,
  Form,
  Hero,
  Menu,
  Prices,
  Schedule,
  Speakers,
  Subjects,
  TimeLine,
} from "../components"
import React from "react"
import { ReviewCarousel } from "../components/ReviewCarousel"

export function MainPage(): React.JSX.Element {
  return (
    <>
      <Menu/>
      <Hero/>
      <About/>
      <Features/>
      <BeInterested/>
      <Subjects/>
      <Schedule/>
      <Speakers/>
      <Prices/>
      <TimeLine/>
      <ReviewCarousel/>
      <Form/>
      <Footer/>
    </>
  )
}
