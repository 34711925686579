import "./App.css"
import "@fontsource/mulish/400.css"
import "@fontsource/mulish/500.css"
import "@fontsource/mulish/600.css"
import "@fontsource/mulish/700.css"
import "@fontsource/mulish/900.css"
import "react-lazy-load-image-component/src/effects/blur.css"

/* lightbox */
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/captions.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"

/* carousel */
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


import { MantineProvider, MantineThemeOverride } from "@mantine/core"
import React, { Suspense } from "react"
import { LoadingScreen, BackToTop } from "../shared/ui"
import { MainPage } from "../pages"
import { FormModal, SpeakerModal } from "../components"
import { DefaultMantineColor, Tuple } from "@mantine/core"

type ExtendedCustomColors = "dark-green" | DefaultMantineColor;

declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
  }
}

// ----------------------------------------------------------------------

export function App(): React.JSX.Element {
  const theme: MantineThemeOverride = {
    fontFamily: "Mulish, sans-serif",
    primaryColor: "dark-green",
    shadows: {
      light: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
    },

    colors: {
      "dark-green": [
        "#f0faef",
        "#e0f3df",
        "#bde5b8",
        "#97d88f",
        "#77cd6d",
        "#63c758",
        "#58c44c",
        "#49ac3d",
        "#3f9935",
        "#318429",
      ],
    },
  }

  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <Suspense fallback={<LoadingScreen/>}>
        <MainPage/>
        <SpeakerModal/>
        <FormModal/>
      </Suspense>
      <BackToTop/>
    </MantineProvider>
  )
}
