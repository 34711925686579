import React from "react"
import { Button, Text, Paper, createStyles, Stack, Group, Center, Divider, Flex } from "@mantine/core"
import { IconCheck, IconDiamondFilled } from "@tabler/icons-react"
import { FormModalStore } from "../../app/FormModalStore"

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    maxWidth: 375,
    position: "relative",
    overflow: "hidden",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },

  innerBox: {
    position: "relative",
    background: theme.fn.linearGradient(45, theme.colors["dark-green"][7], theme.colors.teal[7]),
    boxShadow: "0 20px 40px rgba(0,0,0,0.08)",
    padding: "0 0 30px",
    margin: "0 auto",
  },

  iconBox: {
    position: "relative",
    padding: "50px 30px 0",
    textAlign: "center",
    color: "white",
  },

  textContainer: {
    marginTop: 48,

    [theme.fn.smallerThan("sm")]: {
      marginTop: 24,
    },
  },

  textGroup: {
    gap: theme.spacing.md,

    [theme.fn.smallerThan("sm")]: {
      gap: theme.spacing.xs,
    },
  },

  text: {
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.sm,
    },
  },

  btn: {
    backgroundColor: theme.white,
    color: theme.black,

    "&:hover": {
      backgroundColor: theme.white,
      opacity: 0.9,
    },
  },
}))

function getPrice(): [ number, number | null ] {
  const currentDate = new Date()
  const targetFirstDate = new Date("2024-04-10T00:00:00.000+03:00")
  const targetSecondDate = new Date("2024-04-20T00:00:00.000+03:00")

  if (currentDate < targetFirstDate) {
    return [ 3490, 4490 ]
  }

  if (currentDate < targetSecondDate) {
    return [ 4490, 6000 ]
  }

  return [ 6000, null ]
}

export function PricingPlanSection() {
  const { classes } = useStyles()
  const [ price, prev ] = getPrice()

  return (
    <Paper radius={12} className={classes.wrapper}>
      <div className={classes.innerBox}>
        <div className={classes.iconBox}>
          <IconDiamondFilled size={70} stroke={1.5}/>
        </div>

        <Text color="white" fw={700} align="center" size={22} mb={48}>
          Расширенный
        </Text>

        <Center>
          <Group align="center">
            <Text align="center" size={32} fw={900} color="white" sx={{ position: "relative" }}>
              {price}&nbsp;&#8381;
              {prev && <Text
                ml={12}
                strikethrough
                color="red.8"
                fw={700}
                size={20}
                component="span"
                sx={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "100%",
                }}
              >
                &nbsp;{prev}&nbsp;&#8381;&nbsp;
              </Text>}
            </Text>
          </Group>
        </Center>

        <Center className={classes.textContainer}>
          <Stack spacing={0}>
            <Group className={classes.textGroup}>
              <IconCheck color="white"/>
              <Text c="white" className={classes.text}>Онлайн участие в конференции</Text>
            </Group>
            <Divider my="sm" variant="dashed"/>
            <Group className={classes.textGroup}>
              <IconCheck color="white"/>
              <Text c="white" className={classes.text}>Сертификат участника</Text>
            </Group>
            <Divider my="sm" variant="dashed"/>
            <Group className={classes.textGroup}>
              <IconCheck color="white"/>
              <Text c="white" className={classes.text}>Записи всех выступлений</Text>
            </Group>
          </Stack>
        </Center>

        <Group className={classes.textContainer} position="center">
          <Button
            className={classes.btn}
            radius="md"
            size="md"
            onClick={() => FormModalStore.setData(
              {
                target: `Конференция по нутрициологии. Выбран РАСШИРЕННЫЙ план. Цена: ${price}`,
              })}
          >
            Оставить заявку
          </Button>
        </Group>
      </div>
    </Paper>
  )
}
