import { LeftIcon, RightIcon } from "./arrow-icons"
import { bgBlur } from "./bgBlur"
import { ActionIcon, Box, createStyles, Sx, Text } from "@mantine/core"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  styledRoot: {
    ...bgBlur({
      opacity: 0.48,
      color: theme.colors.gray[9],
    }),
    zIndex: 9,
    display: "inline-flex",
    alignItems: "center",
    position: "absolute",
    bottom: 16,
    right: 16,
    padding: 4,
    color: theme.white,
    borderRadius: theme.radius.md,
  },

  icon: {
    color: theme.white,
    width: 28,
    height: 28,
    padding: 0,
    opacity: 0.48,
    "&:hover": { opacity: 1 },
  },
}))

type Props = {
  index: number;
  total: number;
  onNext?: VoidFunction;
  onPrev?: VoidFunction;
  sx?: Sx
};

export default function CarouselArrowIndex(
  {
    index,
    total,
    onNext,
    onPrev,
    sx,
    ...other
  }: Props) {
  const { classes } = useStyles()

  return (
    <Box className={classes.styledRoot} sx={sx} {...other}>
      <ActionIcon className={classes.icon} variant="transparent" radius="md" onClick={onPrev}>
        <LeftIcon/>
      </ActionIcon>

      <Text component="span" sx={{ margin: "0 4px" }}>
        {index + 1}/{total}
      </Text>

      <ActionIcon className={classes.icon} variant="transparent" radius="md" onClick={onNext}>
        <RightIcon/>
      </ActionIcon>
    </Box>
  )
}
