import { Container, createStyles, Flex, Group, Stack, Text, Title, ActionIcon, Blockquote } from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { IconBrandVk, IconBrandYoutube, IconBrandTelegram, IconBrandWhatsapp } from "@tabler/icons-react"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 60,
    marginBottom: 20,
    paddingBottom: 40,
    backgroundColor: theme.colors["dark-green"][0],
    position: "relative",
    overflowX: "clip",
  },

  wave2: {
    bottom: 0,
    left: 0,
    display: "block",
    position: "absolute",
    height: 40,
    width: "100%",
    background: theme.colors["dark-green"][0],
    transform: "scale(1, 1)",

    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: "white",
      right: "-25%",
      top: 20,
    },

    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.colors["dark-green"][0],
      left: "-25%",
      top: -240,
      clipPath: "ellipse(100% 15% at -15% 100%)",
    },
  },

  flex: {
    [theme.fn.smallerThan(945)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  stack: {
    width: "50%",

    [theme.fn.smallerThan(945)]: {
      width: "100%",
      order: 1,
    },
  },

  image: {
    maxWidth: "30%",
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    border: `5px solid ${theme.colors["dark-green"][8]}`,
    boxShadow: theme.shadows.xl,

    [theme.fn.smallerThan(945)]: {
      order: 0,
      maxWidth: 380,
      marginBottom: 24,
    },

    [theme.fn.smallerThan("xs")]: {
      maxWidth: 220,
    },
  },

  subtitle: {
    [theme.fn.smallerThan(945)]: {
      maxWidth: "unset",
    },
  },

  title: {
    [theme.fn.smallerThan(945)]: {
      textAlign: "center",
    },
  },

  socials: {
    [theme.fn.smallerThan(945)]: {
      justifyContent: "center",
    },
  },
}))

export function About(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <section className={classes.wrapper}>
      <div className={classes.wave2}/>

      <Container size={1320}>
        <Flex align="flex-start" justify="space-around" className={classes.flex}>
          <Stack spacing="xl" className={classes.stack}>
            <Title color="#122c0f" order={3} size={32} className={classes.title}>Идея проекта</Title>
            <Text maw={460} className={classes.subtitle} size={20}>Body Coach - образовательная платформа №1 в сфере
              фитнеса и
              нутрициологии.</Text>

            <Blockquote p={0} color="dark-green.9" cite="– Александр Бурлаков, директор и основатель проекта">
              Мы нацелены на просвещение наших коллег в сфере здоровья, чтобы предотвратить ошибки и негативные
              последствия, вызванные популяризацией антинаучных теорий и методик. Поэтому мы приняли решение провести
              данную конференцию бесплатно, чтобы каждый специалист имел возможность ознакомиться с научным подходом и
              повысить свой уровень компетенции. Будем рады видеть вас на нашем мероприятии. Для нас это очень
              важно.
            </Blockquote>

            <Group className={classes.socials} mt="md" noWrap mb="xl">
              <ActionIcon
                component="a"
                href="https://vk.com/bodycoachschool"
                target="_blank"
                color="blue"
                size="xl"
                variant="filled"
                radius="xl"
              >
                <IconBrandVk/>
              </ActionIcon>
              <ActionIcon
                component="a"
                href="https://www.youtube.com/channel/UCVDLKEfCv2qrwf8q1R-3dKA"
                target="_blank"
                color="red"
                size="xl"
                variant="filled"
                radius="xl"
              >
                <IconBrandYoutube/>
              </ActionIcon>
              <ActionIcon
                component="a"
                href="https://t.me/bodycoach_school"
                target="_blank"
                color="cyan"
                size="xl"
                variant="filled"
                radius="xl"
              >
                <IconBrandTelegram/>
              </ActionIcon>
              <ActionIcon
                component="a"
                href="https://api.whatsapp.com/send/?phone=79952048045&text=ID%3A+QKNSad08wcc&type=phone_number&app_absent=0"
                target="_blank"
                color="green"
                size="xl"
                variant="filled"
                radius="xl"
              >
                <IconBrandWhatsapp/>
              </ActionIcon>
            </Group>

          </Stack>
          <Image
            className={classes.image}
            src="assets/speakers/burlakov/burlakov.jpg"
            alt="Александр Бурлаков"
            ratio="1/1"
            objectFit="contain"
          />
        </Flex>
      </Container>
    </section>
  )
}
