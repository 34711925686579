import { Timeline, Text, Container, Center, createStyles, Title, Group, Flex, Button } from "@mantine/core"
import { IconHandClick, IconExternalLink, IconMessageCircle2, IconLink, IconVip } from "@tabler/icons-react"
import React from "react"
import { Link } from "react-scroll"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 60,
    paddingBottom: 60,
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 80,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors["dark-green"][8],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },

  buttonBox: {
    marginTop: 48,
  },
}))

export function TimeLine(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <Container size={768} className={classes.wrapper}>
      <Center className={classes.title}>
        <Title color="#122c0f" order={2} align="center" size={32}>Как будет проходить конференция?</Title>
        <Text align="center" mt="sm" c="dimmed">Последовательность выполнения заказа</Text>
      </Center>

      <Center>
        <Timeline active={5} bulletSize={32} lineWidth={4}>
          <Timeline.Item color="dark-green.8" bullet={<IconHandClick size={20}/>} title="Шаг 1">
            <Text c="dimmed">Нажмите на кнопку &#34;Регистрация&#34;.</Text>
          </Timeline.Item>

          <Timeline.Item color="dark-green.8" bullet={<IconExternalLink size={20}/>} title="Шаг 2">
            <Text c="dimmed">Вы будете перенаправлены в телеграм-бота, который будет отправлять ссылки на трансляцию с
              выступлениями спикеров и присылать напоминания.</Text>
          </Timeline.Item>

          <Timeline.Item color="dark-green.8" bullet={<IconMessageCircle2 size={20}/>} title="Шаг 3">
            <Text c="dimmed">Получаете сообщение о том, что регистрация успешно выполнена.</Text>
          </Timeline.Item>

          <Timeline.Item color="dark-green.8" bullet={<IconLink size={20}/>} title="Шаг 4">
            <Text c="dimmed">В дни, когда будет проходить конференция, бот вышлет вам ссылку на трансляцию.</Text>
          </Timeline.Item>

          <Timeline.Item color="dark-green.8" bullet={<IconVip size={20}/>} title="Шаг 5">
            <Text c="dimmed">Если вы приобрели платный пакет, то получаете доступ в личном кабинете к материалам
              конференции и
              сертификат участника в течение 3 дней после окончания мероприятия.</Text>
          </Timeline.Item>
        </Timeline>
      </Center>

      <Group className={classes.buttonBox} position="center">
        <Flex align="center" justify="center" direction="column">
          <Button
            variant="gradient"
            gradient={{ from: "dark-green.8", to: "teal", deg: 105 }}
            size="md"
            radius="md"
            offset={-70}
            component={Link}
            to={"prices"}
            smooth={true}
            duration={1000}
          >
            Участвовать
          </Button>
        </Flex>
      </Group>
    </Container>
  )
}
