import React from "react"
import {
  createStyles,
  Title,
  Flex,
  Box,
  Text,
  Modal,
  ScrollArea,
  List,
} from "@mantine/core"
import { Image } from "../../shared/ui"
import { IconPoint } from "@tabler/icons-react"
import { useMediaQuery } from "@mantine/hooks"
import { observer } from "mobx-react-lite"
import { SpeakerStore } from "../../app/SpeakerStore"

const useStyles = createStyles((theme) => ({
  modalContent: {
    width: "100%",
    maxWidth: 1024,

    [theme.fn.smallerThan(1024)]: {
      maxWidth: 480,
    },
  },

  modalContentFlex: {
    [theme.fn.smallerThan(1024)]: {
      flexDirection: "column",
    },
  },

  modalContentTextBox: {
    padding: 48,
    width: "50%",

    [theme.fn.smallerThan(1024)]: {
      width: "100%",
    },

    [theme.fn.smallerThan(768)]: {
      padding: 32,
      paddingLeft: 24,
      paddingRight: 24,
    },

    [theme.fn.smallerThan(480)]: {
      padding: 24,
      paddingLeft: 12,
      paddingRight: 12,
    },
  },

  modalContentTitle: {
    marginBottom: 4,
    fontSize: 32,

    [theme.fn.smallerThan(480)]: {
      textAlign: "center",
      fontSize: 24,
    },
  },

  modalContentDesc: {
    marginBottom: 24,
    fontSize: 22,

    [theme.fn.smallerThan(480)]: {
      textAlign: "center",
      fontSize: 20,
    },
  },

  modalCloseBtn: {
    position: "absolute",
    top: 16,
    right: 16,
    zIndex: 1,
  },
}))

export const SpeakerModal = observer(function SpeakerModal(): React.JSX.Element {
    const { classes } = useStyles()
    const { cover = "", description, speakerName = "Спикер", texts = [] } = SpeakerStore.speaker || {}

    const close = () => SpeakerStore.setIsOpened(false)

    const isTablet = useMediaQuery(`(max-width: 1024px)`)
    const isMobile = useMediaQuery(`(max-width: 480px)`)

    return (
      <Modal.Root scrollAreaComponent={ScrollArea.Autosize} zIndex={2000} size="auto" centered opened={SpeakerStore.isOpened} onClose={close}>
        <Modal.Overlay/>
        <Modal.Content radius={12} className={classes.modalContent}>
          <Modal.CloseButton variant="light" size="xl" radius={12} className={classes.modalCloseBtn}/>
          <Modal.Body p={0}>
            <Flex className={classes.modalContentFlex}>
              <Image
                src={cover}
                ratio={isTablet ? "3/4" : "384/576"}
                sx={{
                  width: isTablet ? "100%" : "50%",
                  backgroundPosition: "center",
                }}
              />
              <Box className={classes.modalContentTextBox}>
                <Title className={classes.modalContentTitle} order={1}>{speakerName}</Title>
                <Text c="dimmed" className={classes.modalContentDesc}>{description}</Text>

                <List
                  spacing="md"
                  size={isMobile ? "md" : "lg"}
                  icon={<IconPoint size={isMobile ? "1rem" : "1.5rem"} color="#2ca959"/>}
                >
                  {texts.map((el, index) => (
                    <List.Item key={index}>{el}</List.Item>
                  ))}
                </List>
              </Box>
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    )
  },
)

