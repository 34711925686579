import axios, { type AxiosResponse } from "axios"
import { API_URL } from "../config"

export type ErrorResponse = {
  message: string
}

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

type Utm = "utm_source" | "utm_medium" | "utm_campaign" | "utm_content" | "utm_term"

export function sendParticipationRequest(_body: Record<string, string>): Promise<AxiosResponse<{
  message: string
}, ErrorResponse>> {
  const referer = window.document.referrer
  const url = new URL(window.location.href)
  const utmData: Record<Utm, string | undefined> = {
    utm_source: undefined,
    utm_medium: undefined,
    utm_campaign: undefined,
    utm_content: undefined,
    utm_term: undefined,
  }
  //
  // if (url.hash) {
  //   const [ , params ] = url.hash.split("/?")
  //   const groups = params.split("&")
  //   const keys = Object.keys(utmData)
  //
  //   groups.forEach((group) => {
  //     const [ key, value ] = group.split("=")
  //
  //     if (keys.includes(key)) {
  //       utmData[key as Utm] = value
  //     }
  //   })
  // } else {
    const params = url.searchParams

    utmData.utm_source = params.get("utm_source") || ""
    utmData.utm_medium = params.get("utm_medium") || ""
    utmData.utm_campaign = params.get("utm_campaign") || ""
    utmData.utm_content = params.get("utm_content") || ""
    utmData.utm_term = params.get("utm_term") || ""
  // }


  const body = {
    ..._body,
    referer,
    utmData,
  }

  return api.post("mail", { ...body, tabName: "Конференция по нутрициологии" })
}
