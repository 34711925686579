import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"

export function LeftIcon() {
  return (
    <IconChevronLeft/>
  )
}

export function RightIcon() {
  return (
    <IconChevronRight/>
  )
}
