import { createStyles } from "@mantine/core"
import React from "react"

// ----------------------------------------------------------------------

const useStyles = createStyles(() => ({
  image: {
    objectFit: "contain",
    verticalAlign: "middle",
    width: 66,
    height: 52
  },
}))

type Props = {
  className?: string
};

export function Logo({ className = "" }: Props): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <div className={className}>
      <img className={classes.image} src="assets/logo.png" alt="Логотип Body Coach"/>
    </div>
  )
}
