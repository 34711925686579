import {
  Container,
  Center,
  createStyles,
  Title,
} from "@mantine/core"
import React from "react"
import { Element } from "./Element"
import { schedule } from "../../shared/utils/constants"

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: 20,
    paddingTop: 40,
    marginBottom: 20,
    paddingBottom: 40,
    backgroundColor: theme.colors["dark-green"][0],
    position: "relative",
    overflowX: "clip",
  },

  wave1: {
    top: 0,
    left: 0,
    display: "block",
    position: "absolute",
    height: 40,
    width: "100%",
    background: theme.colors["dark-green"][0],
    transform: "scale(1, 1)",

    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.colors["dark-green"][0],
      right: "-25%",
      top: -20,
    },

    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.white,
      left: "-25%",
      top: -280,
      clipPath: "ellipse(100% -15% at 15% 100%)",
    },
  },

  wave2: {
    bottom: 0,
    left: 0,
    display: "block",
    position: "absolute",
    height: 40,
    width: "100%",
    background: theme.colors["dark-green"][0],
    transform: "scale(1, 1)",

    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: "white",
      right: "-25%",
      top: 20,
    },

    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.colors["dark-green"][0],
      left: "-25%",
      top: -240,
      clipPath: "ellipse(100% 15% at -15% 100%)",
    },
  },


  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 40,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors["dark-green"][9],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },
}))


export function Schedule(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <section id="schedule" className={classes.wrapper}>
      <div className={classes.wave1}/>
      <div className={classes.wave2}/>

      <Container sx={{ zIndex: 1, position: "relative" }} size={1320}>
        <Center className={classes.title}>
          <Title color="#122c0f" order={2} align="center" size={32}>Расписание выступлений</Title>
        </Center>

        {schedule.map((speaker) => (
          <Element key={speaker.title + speaker.value} speaker={speaker}/>
        ))}
      </Container>
    </section>
  )
}
