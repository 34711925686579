import Zoom from "yet-another-react-lightbox/plugins/zoom"
import Video from "yet-another-react-lightbox/plugins/video"
import Captions from "yet-another-react-lightbox/plugins/captions"
import Slideshow from "yet-another-react-lightbox/plugins/slideshow"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import ReactLightbox, { useLightboxState } from "yet-another-react-lightbox"

import { LightBoxProps } from "./types"
import { Box, createStyles } from "@mantine/core"
import {
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconChevronLeft,
  IconChevronRight,
  IconPlayerPause,
  IconPlayerPlay,
  IconX,
  IconZoomIn,
  IconZoomOut,
} from "@tabler/icons-react"

// ----------------------------------------------------------------------


const useStyles = createStyles((theme) => ({
  lightBox: {
    "&.yarl__root": {
      "--yarl__thumbnails_thumbnail_padding": 0,
      "--yarl__thumbnails_thumbnail_border": "transparent",
      "--yarl__color_backdrop": theme.fn.rgba(theme.colors.gray[9], 0.9),
      "--yarl__slide_captions_container_background": theme.fn.rgba(theme.colors.gray[9], 0.48),
    },
    // Button
    ".yarl__button": {
      filter: "unset",
    },
    // Thumbnails
    ".yarl__thumbnails_thumbnail": {
      borderColor: theme.colors["dark-green"][8],
      borderRadius: theme.radius.md,
      opacity: 0.30,
      "&.yarl__thumbnails_thumbnail_active": {
        opacity: 1,
      },
    },
    ".yarl__thumbnails_vignette": {
      "--yarl__thumbnails_vignette_size": 0,
    },
    // Video
    ".yarl__video_container": {
      backgroundColor: theme.black,
    },

    ".yarl__slide_image": {
      borderRadius: theme.radius.md,
    },
  },
}))


export function Lightbox(
  {
    slides,
    disabledZoom,
    disabledVideo,
    disabledTotal,
    disabledCaptions,
    disabledSlideshow,
    disabledThumbnails,
    disabledFullscreen,
    onGetCurrentIndex,
    ...other
  }: LightBoxProps) {
  const totalItems = slides ? slides.length : 0
  const { classes } = useStyles()

  return (
    <ReactLightbox
      slides={slides}
      className={classes.lightBox}
      animation={{ swipe: 240 }}
      carousel={{ finite: totalItems < 36 }}
      controller={{ closeOnBackdropClick: true }}
      plugins={getPlugins({
        disabledZoom,
        disabledVideo,
        disabledCaptions,
        disabledSlideshow,
        disabledThumbnails,
        disabledFullscreen,
      })}
      on={{
        view: ({ index }: { index: number }) => {
          if (onGetCurrentIndex) {
            onGetCurrentIndex(index)
          }
        },
      }}
      toolbar={{
        buttons: [
          <DisplayTotal key={0} totalItems={totalItems} disabledTotal={disabledTotal}/>,
          "close",
        ],
      }}
      render={{
        iconClose: () => <IconX size={24}/>,
        iconZoomIn: () => <IconZoomIn size={24}/>,
        iconZoomOut: () => <IconZoomOut size={24}/>,
        iconSlideshowPlay: () => <IconPlayerPlay/>,
        iconSlideshowPause: () => <IconPlayerPause/>,
        iconPrev: () => <IconChevronLeft size={32}/>,
        iconNext: () => <IconChevronRight size={32}/>,
        iconExitFullscreen: () => <IconArrowsMinimize size={24}/>,
        iconEnterFullscreen: () => <IconArrowsMaximize size={24}/>,
      }}
      {...other}
    />
  )
}

// ----------------------------------------------------------------------

export function getPlugins(
  {
    disabledZoom,
    disabledVideo,
    disabledCaptions,
    disabledSlideshow,
    disabledThumbnails,
    disabledFullscreen,
  }: LightBoxProps) {
  let plugins = [ Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom ]

  if (disabledThumbnails) {
    plugins = plugins.filter((plugin) => plugin !== Thumbnails)
  }
  if (disabledCaptions) {
    plugins = plugins.filter((plugin) => plugin !== Captions)
  }
  if (disabledFullscreen) {
    plugins = plugins.filter((plugin) => plugin !== Fullscreen)
  }
  if (disabledSlideshow) {
    plugins = plugins.filter((plugin) => plugin !== Slideshow)
  }
  if (disabledZoom) {
    plugins = plugins.filter((plugin) => plugin !== Zoom)
  }
  if (disabledVideo) {
    plugins = plugins.filter((plugin) => plugin !== Video)
  }

  return plugins
}

// ----------------------------------------------------------------------

type DisplayTotalProps = {
  totalItems: number;
  disabledTotal?: boolean;
};

export function DisplayTotal({ totalItems, disabledTotal }: DisplayTotalProps) {
  const { currentIndex } = useLightboxState()

  if (disabledTotal) {
    return null
  }

  return (
    <Box
      component="span"
      className="yarl__button"
      sx={{
        alignItems: "center",
        display: "inline-flex",
        justifyContent: "center",
      }}
    >
      <strong> {currentIndex + 1} </strong> / {totalItems}
    </Box>
  )
}
