import { Slide, SlideImage } from "yet-another-react-lightbox"
import React, { useEffect } from "react"
import { Image } from "../shared/ui"
import { Lightbox, useLightBox } from "./lightbox"
import { useCarousel, CarouselArrowIndex } from "../components/carousel"
import { bgGradient } from "./carousel/bgBlur"
import { Avatar, Box, Center, Container, createStyles, Title } from "@mantine/core"
import Carousel from "react-slick"

const THUMB_SIZE = 96

const slides: Slide[] = [
  { src: "assets/reviews/1.jpg" },
  { src: "assets/reviews/2.jpg" },
  { src: "assets/reviews/3.jpg" },
  { src: "assets/reviews/4.jpg" },
  { src: "assets/reviews/5.jpg" },
  { src: "assets/reviews/6.jpg" },
  { src: "assets/reviews/7.jpg" },
  { src: "assets/reviews/8.jpg" },
  { src: "assets/reviews/9.jpg" },
  { src: "assets/reviews/10.jpg" },
  { src: "assets/reviews/11.jpg" },
  { src: "assets/reviews/12.jpg" },
  { src: "assets/reviews/13.jpg" },
  { src: "assets/reviews/14.jpg" },
  { src: "assets/reviews/15.jpg" },
  { src: "assets/reviews/16.jpg" },
  { src: "assets/reviews/17.jpg" },
  { src: "assets/reviews/18.jpg" },
  { src: "assets/reviews/19.jpg" },
  { src: "assets/reviews/20.jpg" },
  { src: "assets/reviews/21.jpg" },
  { src: "assets/reviews/22.jpg" },
  { src: "assets/reviews/23.jpg" },
  { src: "assets/reviews/24.jpg" },
  { src: "assets/reviews/25.jpg" },
  { src: "assets/reviews/26.jpg" },
  { src: "assets/reviews/27.jpg" },
  { src: "assets/reviews/28.jpg" },
  { src: "assets/reviews/29.jpg" },
  { src: "assets/reviews/30.jpg" },
  { src: "assets/reviews/31.jpg" },
  { src: "assets/reviews/32.jpg" },
  { src: "assets/reviews/33.jpg" },
  { src: "assets/reviews/35.jpg" },
  { src: "assets/reviews/36.jpg" },
  { src: "assets/reviews/37.jpg" },
]

const useStyles = createStyles((theme, { length }: { length: number }) => ({
  wrapper: {
    paddingTop: 60,
    paddingBottom: 60,
  },

  thumbnailsContainer: {
    position: "relative",
    margin: "0 auto",
    "& .slick-slide": {
      lineHeight: 0,
    },

    ...(length === 1 && {
      maxWidth: THUMB_SIZE + 16,
    }),

    ...(length === 2 && {
      maxWidth: THUMB_SIZE * 2 + 32,
    }),

    ...((length === 3 || length === 4) && {
      maxWidth: THUMB_SIZE * 3 + 48,
    }),

    ...(length >= 5 && {
      maxWidth: THUMB_SIZE * 6,
    }),

    ...(length > 3 && {
      "&:before, &:after": {
        ...bgGradient({
          direction: "to left",
          startColor: `${theme.fn.rgba(theme.white, 0)} 0%`,
          endColor: `${theme.white} 100%`,
        }),
        top: 0,
        zIndex: 9,
        content: "''",
        height: "100%",
        position: "absolute",
        width: (THUMB_SIZE * 2) / 3,
      },
      "&:after": {
        right: -1,
        transform: "scaleX(-1)",
      },
    }),
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 48,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors["dark-green"][8],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },
}))

export function ReviewCarousel() {
  const { theme, classes } = useStyles({ length: slides.length })
  const lightbox = useLightBox(slides)

  const carouselLarge = useCarousel({
    draggable: false,
    adaptiveHeight: true,
  })

  const carouselThumb = useCarousel({
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: "0px",
    slidesToShow: slides.length > 3 ? 3 : slides.length,
  })

  useEffect(() => {
    carouselLarge.onSetNav()
    carouselThumb.onSetNav()
  }, [ carouselLarge, carouselThumb ])

  useEffect(() => {
    if (lightbox.open) {
      carouselLarge.onTogo(lightbox.selected)
    }
  }, [ carouselLarge, lightbox.open, lightbox.selected ])

  const renderLargeImg = (
    <Box
      sx={{
        marginBottom: 24,
        borderRadius: 16,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Carousel
        {...carouselLarge.carouselSettings}
        asNavFor={carouselThumb.nav}
        ref={carouselLarge.carouselRef}
      >
        {slides.map((slide) => (
          <Image
            key={(slide as SlideImage).src}
            alt={(slide as SlideImage).src}
            src={(slide as SlideImage).src}
            ratio="2/3"
            onClick={() => lightbox.onOpen((slide as SlideImage).src)}
            sx={{ cursor: "zoom-in" }}
          />
        ))}
      </Carousel>

      <CarouselArrowIndex
        index={carouselLarge.currentIndex}
        total={slides.length}
        onNext={carouselThumb.onNext}
        onPrev={carouselThumb.onPrev}
      />
    </Box>
  )

  const renderThumbnails = (
    <div className={classes.thumbnailsContainer}>
      <Carousel
        {...carouselThumb.carouselSettings}
        asNavFor={carouselLarge.nav}
        ref={carouselThumb.carouselRef}
      >
        {slides.map((slide, index) => (
          <Box key={(slide as SlideImage).src} sx={{ padding: "0 4px" }}>
            <Avatar
              key={(slide as SlideImage).src}
              alt={(slide as SlideImage).src}
              src={(slide as SlideImage).src}
              radius="lg"
              sx={{
                width: THUMB_SIZE,
                height: THUMB_SIZE,
                opacity: 0.48,
                cursor: "pointer",
                ...(carouselLarge.currentIndex === index && {
                  opacity: 1,
                  border: `solid 3px ${theme.colors["dark-green"][8]}`,
                }),
              }}
            />
          </Box>
        ))}
      </Carousel>
    </div>
  )

  return (
    <div className={classes.wrapper}>
      <Center className={classes.title}>
        <Title color="#122c0f" order={2} align="center" size={32}>Отзывы с прошлой конференции</Title>
      </Center>

      <Container size={640}>
        <Box
          sx={{
            "& .slick-slide": {
              float: "left",
            },
          }}
        >
          {renderLargeImg}

          {renderThumbnails}

          <Lightbox
            index={lightbox.selected}
            slides={slides}
            open={lightbox.open}
            close={lightbox.onClose}
            onGetCurrentIndex={(index) => lightbox.setSelected(index)}
          />
        </Box>
      </Container>
    </div>
  )
}
